import React from "react";
import Layout from "components/layout";
// import FinalTimer from "components/Timer/FinalTimer";
import SeoNOIndex from "components/SeoNoIndex";
import FinalTimerNew from "components/Timer/FinalTimerNew";

function Index() {
  return (
    <>
      <Layout>
        <SeoNOIndex />
        <FinalTimerNew />
      </Layout>
    </>
  );
}

export default Index;
