import React from "react";
import { Link, navigate, useLocation } from "@reach/router";
// import queryString from "query-string";
import moment from "moment";
import CountdownTimer from "./CountdownTimer";
import { useFetchProjectTimer } from "hooks/ useFetchProjectTimer";
import loadable from "@loadable/component";
// import Coupon from "./Coupon";
const Coupon = loadable(() => import("./Coupon"));

function FinalTimerNew() {
  const location = useLocation();
  // const { order_id } = queryString.parse(location.search);
  const urlSearchParams = new URLSearchParams(location.search);
  const { order_id } = Object.fromEntries(urlSearchParams.entries());

  const { data } = useFetchProjectTimer(order_id);
  // console.log("Data", data?.data?.timer_link_date.replace("%2B", "+"));
  const formattedDate = data?.data?.timer_link_date?.replace("%2B", "+");
  const date = moment.parseZone(formattedDate);
  const localDate = moment.parseZone(formattedDate).local();
  const formattedDateUtc = localDate.format("DD/MM/YYYY LT [GMT]Z");

  const handleRedirect = () => {
    window.open(data?.data?.translation_folder_link, "_blank");
  };

  return (
    <div>
      <div className="w-11/12 lg:w-full max-w-6xl mx-auto lg:px-5 py-24 relative">
        {/* project details */}
        <div className="flex justify-between pb-16">
          {data?.success === 1 ? (
            <>
              <p className="font-medium text-base lg:text-lg capitalize">
                Your project number -{" "}
                <span className="font-bold">{data?.data?.pno}</span>
              </p>
              <p className="font-medium text-base lg:text-lg capitalize">
                Your Tomedes Customer Success manager -{" "}
                <span className="font-bold">
                  {data?.data?.customer_success_manager}
                </span>
              </p>
            </>
          ) : (
            <>
              {/* shimmer */}
              <span className="shine w-full max-w-w-64 h-7"></span>
              <span className="shine w-full max-w-[500px] h-7"></span>
            </>
          )}
        </div>
        {/* timer heading */}
        <p className="text-lg sm:text-2xl text-darkBlue font-secondary font-semibold text-center pb-5 flex items-center justify-center">
          {data?.success === 1 ? (
            data?.data?.sales_order_status === "Delivered" ? null : data?.data
                ?.timer_link_date ? (
              <>
                Estimated Due Date &nbsp;
                <span className="text-green-700">{formattedDateUtc}</span>
              </>
            ) : (
              "The Estimated Due Date will be updated soon."
            )
          ) : (
            <span className="shine w-full max-w-[378px] h-[32px]"></span>
          )}
        </p>
        {/* timer */}

        <div className="w-full">
          {!data ? (
            <span className="shine w-full max-w-[1080px] h-[200px] mx-auto"></span>
          ) : data?.success === 1 &&
            data?.data?.timer_link_date &&
            data?.data?.sales_order_status !== "Delivered" ? (
            <div className="w-full">
              <CountdownTimer
                targetDate={date}
                formattedDateUtc={formattedDateUtc}
              />
            </div>
          ) : !data?.data?.sales_order_status ? null : (
            <p className="text-xl sm:text-2xl text-green-700 font-opensans font-semibold text-center">
              This Project has been delivered
            </p>
          )}

          {/* error message */}
          {data?.success === 0 ? (
            <p className="text-red text-center text-3xl">{data?.message}</p>
          ) : null}
        </div>

        {data?.data?.show_reward &&
          data?.data?.reward !== "" &&
          data?.data?.id !== "" && (
            <Coupon
              reward={data?.data?.reward}
              id={data?.data?.id}
              used={data?.data?.is_reward_used}
            />
          )}

        {data?.data?.translation_folder_link && (
          <div className="  flex items-center px-[10px] py-[10px] justify-center">
            <span
              onClick={handleRedirect}
              className="uppercase rounded-full bg-orange hover:bg-newOrange-100 text-white cursor-pointer text-17 font-semibold py-4 px-8 text-center transition-colors duration-300 mt-6"
            >
              Download Certificate
            </span>
          </div>
        )}

        {/* <Coupon
          reward={data?.data?.reward}
          id={data?.data?.id}
          used={data?.data?.is_reward_used}
        /> */}
      </div>
    </div>
  );
}

export default FinalTimerNew;
