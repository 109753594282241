import { useQuery } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const fetchProjectTimer = (pno) => {
  const newEndpoints = endpoints.projectTimer.getTimer.replace(
    "?pno=164672",
    "?pno=" + pno
  );
  // if (id === 0) return;
  return http().get(newEndpoints);
};

export function useFetchProjectTimer(pno) {
  return useQuery(["useFetchProjectTimer", pno], () => fetchProjectTimer(pno));
}
